"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = exports["default"] = {
  "code[class*=\"language-\"]": {
    "color": "#ebdbb2",
    "fontFamily": "Consolas, Monaco, \"Andale Mono\", monospace",
    "direction": "ltr",
    "textAlign": "left",
    "whiteSpace": "pre",
    "wordSpacing": "normal",
    "wordBreak": "normal",
    "lineHeight": "1.5",
    "MozTabSize": "4",
    "OTabSize": "4",
    "tabSize": "4",
    "WebkitHyphens": "none",
    "MozHyphens": "none",
    "msHyphens": "none",
    "hyphens": "none"
  },
  "pre[class*=\"language-\"]": {
    "color": "#ebdbb2",
    "fontFamily": "Consolas, Monaco, \"Andale Mono\", monospace",
    "direction": "ltr",
    "textAlign": "left",
    "whiteSpace": "pre",
    "wordSpacing": "normal",
    "wordBreak": "normal",
    "lineHeight": "1.5",
    "MozTabSize": "4",
    "OTabSize": "4",
    "tabSize": "4",
    "WebkitHyphens": "none",
    "MozHyphens": "none",
    "msHyphens": "none",
    "hyphens": "none",
    "padding": "1em",
    "margin": "0.5em 0",
    "overflow": "auto",
    "background": "#1d2021"
  },
  "pre[class*=\"language-\"]::-moz-selection": {
    "color": "#fbf1c7",
    "background": "#7c6f64"
  },
  "pre[class*=\"language-\"] ::-moz-selection": {
    "color": "#fbf1c7",
    "background": "#7c6f64"
  },
  "code[class*=\"language-\"]::-moz-selection": {
    "color": "#fbf1c7",
    "background": "#7c6f64"
  },
  "code[class*=\"language-\"] ::-moz-selection": {
    "color": "#fbf1c7",
    "background": "#7c6f64"
  },
  "pre[class*=\"language-\"]::selection": {
    "color": "#fbf1c7",
    "background": "#7c6f64"
  },
  "pre[class*=\"language-\"] ::selection": {
    "color": "#fbf1c7",
    "background": "#7c6f64"
  },
  "code[class*=\"language-\"]::selection": {
    "color": "#fbf1c7",
    "background": "#7c6f64"
  },
  "code[class*=\"language-\"] ::selection": {
    "color": "#fbf1c7",
    "background": "#7c6f64"
  },
  ":not(pre) > code[class*=\"language-\"]": {
    "background": "#1d2021",
    "padding": "0.1em",
    "borderRadius": "0.3em"
  },
  "comment": {
    "color": "#a89984"
  },
  "prolog": {
    "color": "#a89984"
  },
  "cdata": {
    "color": "#a89984"
  },
  "delimiter": {
    "color": "#fb4934"
  },
  "boolean": {
    "color": "#fb4934"
  },
  "keyword": {
    "color": "#fb4934"
  },
  "selector": {
    "color": "#fb4934"
  },
  "important": {
    "color": "#fb4934"
  },
  "atrule": {
    "color": "#fb4934"
  },
  "operator": {
    "color": "#a89984"
  },
  "punctuation": {
    "color": "#a89984"
  },
  "attr-name": {
    "color": "#a89984"
  },
  "tag": {
    "color": "#fabd2f"
  },
  "tag.punctuation": {
    "color": "#fabd2f"
  },
  "doctype": {
    "color": "#fabd2f"
  },
  "builtin": {
    "color": "#fabd2f"
  },
  "entity": {
    "color": "#d3869b"
  },
  "number": {
    "color": "#d3869b"
  },
  "symbol": {
    "color": "#d3869b"
  },
  "property": {
    "color": "#fb4934"
  },
  "constant": {
    "color": "#fb4934"
  },
  "variable": {
    "color": "#fb4934"
  },
  "string": {
    "color": "#b8bb26"
  },
  "char": {
    "color": "#b8bb26"
  },
  "attr-value": {
    "color": "#a89984"
  },
  "attr-value.punctuation": {
    "color": "#a89984"
  },
  "url": {
    "color": "#b8bb26",
    "textDecoration": "underline"
  },
  "function": {
    "color": "#fabd2f"
  },
  "regex": {
    "background": "#b8bb26"
  },
  "bold": {
    "fontWeight": "bold"
  },
  "italic": {
    "fontStyle": "italic"
  },
  "inserted": {
    "background": "#a89984"
  },
  "deleted": {
    "background": "#fb4934"
  }
};