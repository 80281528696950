"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = exports["default"] = {
  "code[class*='language-']": {
    "color": "#d6e7ff",
    "background": "#030314",
    "textShadow": "none",
    "fontFamily": "Consolas, Monaco, \"Andale Mono\", \"Ubuntu Mono\", monospace",
    "fontSize": "1em",
    "lineHeight": "1.5",
    "letterSpacing": ".2px",
    "whiteSpace": "pre",
    "wordSpacing": "normal",
    "wordBreak": "normal",
    "wordWrap": "normal",
    "textAlign": "left",
    "MozTabSize": "4",
    "OTabSize": "4",
    "tabSize": "4",
    "WebkitHyphens": "none",
    "MozHyphens": "none",
    "msHyphens": "none",
    "hyphens": "none"
  },
  "pre[class*='language-']": {
    "color": "#d6e7ff",
    "background": "#030314",
    "textShadow": "none",
    "fontFamily": "Consolas, Monaco, \"Andale Mono\", \"Ubuntu Mono\", monospace",
    "fontSize": "1em",
    "lineHeight": "1.5",
    "letterSpacing": ".2px",
    "whiteSpace": "pre",
    "wordSpacing": "normal",
    "wordBreak": "normal",
    "wordWrap": "normal",
    "textAlign": "left",
    "MozTabSize": "4",
    "OTabSize": "4",
    "tabSize": "4",
    "WebkitHyphens": "none",
    "MozHyphens": "none",
    "msHyphens": "none",
    "hyphens": "none",
    "border": "1px solid #2a4555",
    "borderRadius": "5px",
    "padding": "1.5em 1em",
    "margin": "1em 0",
    "overflow": "auto"
  },
  "pre[class*='language-']::-moz-selection": {
    "color": "inherit",
    "background": "#1d3b54",
    "textShadow": "none"
  },
  "pre[class*='language-'] ::-moz-selection": {
    "color": "inherit",
    "background": "#1d3b54",
    "textShadow": "none"
  },
  "code[class*='language-']::-moz-selection": {
    "color": "inherit",
    "background": "#1d3b54",
    "textShadow": "none"
  },
  "code[class*='language-'] ::-moz-selection": {
    "color": "inherit",
    "background": "#1d3b54",
    "textShadow": "none"
  },
  "pre[class*='language-']::selection": {
    "color": "inherit",
    "background": "#1d3b54",
    "textShadow": "none"
  },
  "pre[class*='language-'] ::selection": {
    "color": "inherit",
    "background": "#1d3b54",
    "textShadow": "none"
  },
  "code[class*='language-']::selection": {
    "color": "inherit",
    "background": "#1d3b54",
    "textShadow": "none"
  },
  "code[class*='language-'] ::selection": {
    "color": "inherit",
    "background": "#1d3b54",
    "textShadow": "none"
  },
  ":not(pre) > code[class*='language-']": {
    "color": "#f0f6f6",
    "background": "#2a4555",
    "padding": "0.2em 0.3em",
    "borderRadius": "0.2em",
    "boxDecorationBreak": "clone"
  },
  "comment": {
    "color": "#446e69"
  },
  "prolog": {
    "color": "#446e69"
  },
  "doctype": {
    "color": "#446e69"
  },
  "cdata": {
    "color": "#446e69"
  },
  "punctuation": {
    "color": "#d6b007"
  },
  "property": {
    "color": "#d6e7ff"
  },
  "tag": {
    "color": "#d6e7ff"
  },
  "boolean": {
    "color": "#d6e7ff"
  },
  "number": {
    "color": "#d6e7ff"
  },
  "constant": {
    "color": "#d6e7ff"
  },
  "symbol": {
    "color": "#d6e7ff"
  },
  "deleted": {
    "color": "#d6e7ff"
  },
  "selector": {
    "color": "#e60067"
  },
  "attr-name": {
    "color": "#e60067"
  },
  "builtin": {
    "color": "#e60067"
  },
  "inserted": {
    "color": "#e60067"
  },
  "string": {
    "color": "#49c6ec"
  },
  "char": {
    "color": "#49c6ec"
  },
  "operator": {
    "color": "#ec8e01",
    "background": "transparent"
  },
  "entity": {
    "color": "#ec8e01",
    "background": "transparent"
  },
  "url": {
    "color": "#ec8e01",
    "background": "transparent"
  },
  ".language-css .token.string": {
    "color": "#ec8e01",
    "background": "transparent"
  },
  ".style .token.string": {
    "color": "#ec8e01",
    "background": "transparent"
  },
  "atrule": {
    "color": "#0fe468"
  },
  "attr-value": {
    "color": "#0fe468"
  },
  "keyword": {
    "color": "#0fe468"
  },
  "function": {
    "color": "#78f3e9"
  },
  "class-name": {
    "color": "#78f3e9"
  },
  "regex": {
    "color": "#d6e7ff"
  },
  "important": {
    "color": "#d6e7ff"
  },
  "variable": {
    "color": "#d6e7ff"
  }
};