"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = exports["default"] = {
  "code[class*=\"language-\"]": {
    "color": "black",
    "background": "none",
    "fontFamily": "Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace",
    "fontSize": "1em",
    "textAlign": "left",
    "whiteSpace": "pre",
    "wordSpacing": "normal",
    "wordBreak": "normal",
    "wordWrap": "normal",
    "lineHeight": "1.5",
    "MozTabSize": "4",
    "OTabSize": "4",
    "tabSize": "4",
    "WebkitHyphens": "none",
    "MozHyphens": "none",
    "msHyphens": "none",
    "hyphens": "none"
  },
  "pre[class*=\"language-\"]": {
    "color": "black",
    "background": "none",
    "fontFamily": "Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace",
    "fontSize": "1em",
    "textAlign": "left",
    "whiteSpace": "pre",
    "wordSpacing": "normal",
    "wordBreak": "normal",
    "wordWrap": "normal",
    "lineHeight": "1.5",
    "MozTabSize": "4",
    "OTabSize": "4",
    "tabSize": "4",
    "WebkitHyphens": "none",
    "MozHyphens": "none",
    "msHyphens": "none",
    "hyphens": "none",
    "position": "relative",
    "borderLeft": "10px solid #358ccb",
    "boxShadow": "-1px 0 0 0 #358ccb, 0 0 0 1px #dfdfdf",
    "backgroundColor": "#fdfdfd",
    "backgroundImage": "linear-gradient(transparent 50%, rgba(69, 142, 209, 0.04) 50%)",
    "backgroundSize": "3em 3em",
    "backgroundOrigin": "content-box",
    "backgroundAttachment": "local",
    "margin": ".5em 0",
    "padding": "0 1em"
  },
  "pre[class*=\"language-\"] > code": {
    "display": "block"
  },
  ":not(pre) > code[class*=\"language-\"]": {
    "position": "relative",
    "padding": ".2em",
    "borderRadius": "0.3em",
    "color": "#c92c2c",
    "border": "1px solid rgba(0, 0, 0, 0.1)",
    "display": "inline",
    "whiteSpace": "normal",
    "backgroundColor": "#fdfdfd",
    "WebkitBoxSizing": "border-box",
    "MozBoxSizing": "border-box",
    "boxSizing": "border-box"
  },
  "comment": {
    "color": "#7D8B99"
  },
  "block-comment": {
    "color": "#7D8B99"
  },
  "prolog": {
    "color": "#7D8B99"
  },
  "doctype": {
    "color": "#7D8B99"
  },
  "cdata": {
    "color": "#7D8B99"
  },
  "punctuation": {
    "color": "#5F6364"
  },
  "property": {
    "color": "#c92c2c"
  },
  "tag": {
    "color": "#c92c2c"
  },
  "boolean": {
    "color": "#c92c2c"
  },
  "number": {
    "color": "#c92c2c"
  },
  "function-name": {
    "color": "#c92c2c"
  },
  "constant": {
    "color": "#c92c2c"
  },
  "symbol": {
    "color": "#c92c2c"
  },
  "deleted": {
    "color": "#c92c2c"
  },
  "selector": {
    "color": "#2f9c0a"
  },
  "attr-name": {
    "color": "#2f9c0a"
  },
  "string": {
    "color": "#2f9c0a"
  },
  "char": {
    "color": "#2f9c0a"
  },
  "function": {
    "color": "#2f9c0a"
  },
  "builtin": {
    "color": "#2f9c0a"
  },
  "inserted": {
    "color": "#2f9c0a"
  },
  "operator": {
    "color": "#a67f59",
    "background": "rgba(255, 255, 255, 0.5)"
  },
  "entity": {
    "color": "#a67f59",
    "background": "rgba(255, 255, 255, 0.5)",
    "cursor": "help"
  },
  "url": {
    "color": "#a67f59",
    "background": "rgba(255, 255, 255, 0.5)"
  },
  "variable": {
    "color": "#a67f59",
    "background": "rgba(255, 255, 255, 0.5)"
  },
  "atrule": {
    "color": "#1990b8"
  },
  "attr-value": {
    "color": "#1990b8"
  },
  "keyword": {
    "color": "#1990b8"
  },
  "class-name": {
    "color": "#1990b8"
  },
  "regex": {
    "color": "#e90"
  },
  "important": {
    "color": "#e90",
    "fontWeight": "normal"
  },
  ".language-css .token.string": {
    "color": "#a67f59",
    "background": "rgba(255, 255, 255, 0.5)"
  },
  ".style .token.string": {
    "color": "#a67f59",
    "background": "rgba(255, 255, 255, 0.5)"
  },
  "bold": {
    "fontWeight": "bold"
  },
  "italic": {
    "fontStyle": "italic"
  },
  "namespace": {
    "Opacity": ".7"
  }
};