"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = exports["default"] = {
  "code[class*='language-']": {
    "color": "#9efeff",
    "direction": "ltr",
    "textAlign": "left",
    "whiteSpace": "pre",
    "wordSpacing": "normal",
    "wordBreak": "normal",
    "MozTabSize": "4",
    "OTabSize": "4",
    "tabSize": "4",
    "WebkitHyphens": "none",
    "MozHyphens": "none",
    "msHyphens": "none",
    "hyphens": "none",
    "fontFamily": "'Operator Mono', 'Fira Code', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace",
    "fontWeight": "400",
    "fontSize": "17px",
    "lineHeight": "25px",
    "letterSpacing": "0.5px",
    "textShadow": "0 1px #222245"
  },
  "pre[class*='language-']": {
    "color": "#9efeff",
    "direction": "ltr",
    "textAlign": "left",
    "whiteSpace": "pre",
    "wordSpacing": "normal",
    "wordBreak": "normal",
    "MozTabSize": "4",
    "OTabSize": "4",
    "tabSize": "4",
    "WebkitHyphens": "none",
    "MozHyphens": "none",
    "msHyphens": "none",
    "hyphens": "none",
    "fontFamily": "'Operator Mono', 'Fira Code', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace",
    "fontWeight": "400",
    "fontSize": "17px",
    "lineHeight": "25px",
    "letterSpacing": "0.5px",
    "textShadow": "0 1px #222245",
    "padding": "2em",
    "margin": "0.5em 0",
    "overflow": "auto",
    "background": "#1e1e3f"
  },
  "pre[class*='language-']::-moz-selection": {
    "color": "inherit",
    "background": "#a599e9"
  },
  "pre[class*='language-'] ::-moz-selection": {
    "color": "inherit",
    "background": "#a599e9"
  },
  "code[class*='language-']::-moz-selection": {
    "color": "inherit",
    "background": "#a599e9"
  },
  "code[class*='language-'] ::-moz-selection": {
    "color": "inherit",
    "background": "#a599e9"
  },
  "pre[class*='language-']::selection": {
    "color": "inherit",
    "background": "#a599e9"
  },
  "pre[class*='language-'] ::selection": {
    "color": "inherit",
    "background": "#a599e9"
  },
  "code[class*='language-']::selection": {
    "color": "inherit",
    "background": "#a599e9"
  },
  "code[class*='language-'] ::selection": {
    "color": "inherit",
    "background": "#a599e9"
  },
  ":not(pre) > code[class*='language-']": {
    "background": "#1e1e3f",
    "padding": "0.1em",
    "borderRadius": "0.3em"
  },
  "": {
    "fontWeight": "400"
  },
  "comment": {
    "color": "#b362ff"
  },
  "prolog": {
    "color": "#b362ff"
  },
  "cdata": {
    "color": "#b362ff"
  },
  "delimiter": {
    "color": "#ff9d00"
  },
  "keyword": {
    "color": "#ff9d00"
  },
  "selector": {
    "color": "#ff9d00"
  },
  "important": {
    "color": "#ff9d00"
  },
  "atrule": {
    "color": "#ff9d00"
  },
  "operator": {
    "color": "rgb(255, 180, 84)",
    "background": "none"
  },
  "attr-name": {
    "color": "rgb(255, 180, 84)"
  },
  "punctuation": {
    "color": "#ffffff"
  },
  "boolean": {
    "color": "rgb(255, 98, 140)"
  },
  "tag": {
    "color": "rgb(255, 157, 0)"
  },
  "tag.punctuation": {
    "color": "rgb(255, 157, 0)"
  },
  "doctype": {
    "color": "rgb(255, 157, 0)"
  },
  "builtin": {
    "color": "rgb(255, 157, 0)"
  },
  "entity": {
    "color": "#6897bb",
    "background": "none"
  },
  "symbol": {
    "color": "#6897bb"
  },
  "number": {
    "color": "#ff628c"
  },
  "property": {
    "color": "#ff628c"
  },
  "constant": {
    "color": "#ff628c"
  },
  "variable": {
    "color": "#ff628c"
  },
  "string": {
    "color": "#a5ff90"
  },
  "char": {
    "color": "#a5ff90"
  },
  "attr-value": {
    "color": "#a5c261"
  },
  "attr-value.punctuation": {
    "color": "#a5c261"
  },
  "attr-value.punctuation:first-child": {
    "color": "#a9b7c6"
  },
  "url": {
    "color": "#287bde",
    "textDecoration": "underline",
    "background": "none"
  },
  "function": {
    "color": "rgb(250, 208, 0)"
  },
  "regex": {
    "background": "#364135"
  },
  "bold": {
    "fontWeight": "bold"
  },
  "italic": {
    "fontStyle": "italic"
  },
  "inserted": {
    "background": "#00ff00"
  },
  "deleted": {
    "background": "#ff000d"
  },
  "code.language-css .token.property": {
    "color": "#a9b7c6"
  },
  "code.language-css .token.property + .token.punctuation": {
    "color": "#a9b7c6"
  },
  "code.language-css .token.id": {
    "color": "#ffc66d"
  },
  "code.language-css .token.selector > .token.class": {
    "color": "#ffc66d"
  },
  "code.language-css .token.selector > .token.attribute": {
    "color": "#ffc66d"
  },
  "code.language-css .token.selector > .token.pseudo-class": {
    "color": "#ffc66d"
  },
  "code.language-css .token.selector > .token.pseudo-element": {
    "color": "#ffc66d"
  },
  "class-name": {
    "color": "#fb94ff"
  },
  ".language-css .token.string": {
    "background": "none"
  },
  ".style .token.string": {
    "background": "none"
  },
  ".line-highlight.line-highlight": {
    "marginTop": "36px",
    "background": "linear-gradient(to right, rgba(179, 98, 255, 0.17), transparent)"
  },
  ".line-highlight.line-highlight:before": {
    "content": "''"
  },
  ".line-highlight.line-highlight[data-end]:after": {
    "content": "''"
  }
};